<template>
    <div>
        <Modal :options="{width: '70vw', close: true}" @close="$emit('close')">
            <div class="title">Documentos {{ area_visual }}</div>
            <div class="body">
                <div class="row">
                    <div v-for="documento in documentos" class="col-sm-3 mb-3">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title text-truncate" :title="documento.nombre">{{ documento.nombre }}</h5>
                                <ul>
                                    <li>Tipo: {{ documento.extension }}</li>
                                    <li>Peso: {{ $helper.bytesConverted(documento.tamano) }}</li>
                                    <li>Tag: {{ documento.tag }}</li>
                                    <li v-if="tipo_documento">S3: {{ documento.archivo.indexOf('s3') != -1 ? 's3' : 'directo' }}</li>
                                </ul>
                                <div class="text-right">
                                    <a href="#" class="card-link" @click.prevent="descargar_documento(documento)">Descargar documento</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>

        <Modal v-if="modal_ver_doc" :options="{close: true}" @close="modal_ver_doc=false">
            <div class="title">Documento</div>
            <div class="body">
                <iframe class="iframe" :src="url_file" frameborder="0"></iframe>
            </div>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/Modal'

import apiSolicitudes from '@/apps/solicitudes/api/solicitudes'

export default {
    components: {
        Modal
    }
    ,props: {
        solicitud: {
            type: Object
            ,default() {
                return {

                }
            }
        }
        ,area: {
            type: String
            ,default: 'callback'
        }
    }
    ,data() {
        return {
            modal_ver_doc: false
            ,url_file: null
        }
    }
    ,methods: {
        tipo_valido(tipo) {
            let tipos = ['pdf','.pdf','jpg','.jpg','jpeg','.jpeg','png','.png','bmp','.bmp','gif','.gif'];

            return tipos.indexOf(tipo) > -1;
        }
        ,async descargar_documento(documento) {
            try {
                let res = (await apiSolicitudes.obtener_documento(this.solicitud.id, documento.id)).data;

                let mime = {pdf: 'application/pdf', jpg: 'image/jpeg', jpeg: 'image/jpeg', png: 'image/png', bmp: 'image/bmp', gif: 'image/gif'};
                let extension = documento.extension.replace('.','');
                this.$helper.descargar_documento(res,documento.nombre);
                // this.url_file = window.URL.createObjectURL(new Blob([res], {type: mime[extension]}));
                // this.modal_ver_doc = true;
            }catch(e) {
                this.$log.info('error',e);
                this.$helper.showMessage('Error','Error al descargar documento: '+e.message,'error','alert');
                // this.$helper.showAxiosError(e,'Error');
            }
        }
    }
    ,computed: {
        documentos() {
            let documentos = [];

            this.solicitud.documentos.forEach(doc => {
                if (doc.modulo == this.area)
                    documentos.push(doc);
            })

            return documentos;
        }
        ,area_visual() {
            switch(this.area) {
                case 'callback':
                    return 'CallBack';
                case 'opinion':
                    return 'Opinión de valor';
                case 'citas':
                    return 'Preautorización';
                case 'entrevistas':
                    return 'Documentación';
                case 'comite':
                    return 'Autorización';
                case 'ejercido':
                    return 'Ejercido';
                case 'desarrollo':
                    return 'Desarrollo Empresarial';
            }
        }
        ,tipo_documento() {
            return process.env.VUE_APP_ENV == 'local';
        }
    }
}
</script>

<style lang="scss">
    .iframe {
        width: 100%;
        height: 70vh;
    }
</style>